<template>
  <div
    class="container rx-wrapper pl-0 pr-0"
    style="background: #ffffff"
    id="sign"
  >
    <div class="text-color text-center mt-5">
        <h3>The site follows the Terms and condition, policies, and guidelines described here.</h3>
    </div>

      <section class="banner-three">
        <div class="accordion box-shadow mt-4" id="accordionSettings">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed text-color border-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#tasknameone"
                aria-expanded="true"
                aria-controls="tasknameone"
              >
                <strong>Terms and conditions - Public</strong>
              </button>
            </h2>
            <div
              id="tasknameone"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionSettings"
            >
              <div class="accordion-body p-4"></div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed text-color border-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#tasknametwo"
                aria-expanded="true"
                aria-controls="tasknametwo"
              >
                <strong>Terms and conditions - Doctor</strong>
              </button>
            </h2>
            <div
              id="tasknametwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionSettings"
            >
              <div class="accordion-body p-4"></div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed text-color border-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#tasknamethree"
                aria-expanded="true"
                aria-controls="tasknamethree"
              >
                <strong>Privacy policy</strong>
              </button>
            </h2>
            <div
              id="tasknamethree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionSettings"
            >
              <div class="accordion-body p-4"></div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed text-color border-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#tasknamefour"
                aria-expanded="true"
                aria-controls="tasknamefour"
              >
                <strong>Home visit request cancellation policy</strong>
              </button>
            </h2>
            <div
              id="tasknamefour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionSettings"
            >
              <div class="accordion-body p-4"></div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed text-color border-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#tasknamefive"
                aria-expanded="true"
                aria-controls="tasknamefive"
              >
                <strong>Community guidelines</strong>
              </button>
            </h2>
            <div
              id="tasknamefive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionSettings"
            >
              <div class="accordion-body p-4"></div>
            </div>
          </div>
        </div>
      </section>

      <div class="tab-bar-button d-xs-block d-sm-block d-md-none d-lg-none">
        <div
          id="buttonGroup"
          class="btn-group selectors"
          role="group"
          aria-label="Basic example"
        >
          <button
            id="home"
            type="button"
            class="btn btn-secondary button-active"
          >
            <a href="/home-care">
              <div class="selector-holder">
                <i class="material-icons">person_outline</i>
                <span>Public</span>
              </div>
            </a>
          </button>
          <button
            id="account"
            type="button"
            class="btn btn-secondary button-inactive"
          >
            <a href="/contact-us">
              <div class="selector-holder">
                <i class="material-icons">settings</i>
                <span>Patient Support</span>
              </div>
            </a>
          </button>

          <button
            id="account"
            type="button"
            class="btn btn-secondary button-inactive"
          >
            <a href="/home-doctor">
              <div class="selector-holder">
                <i class="material-icons">group</i>
                <span>Doctors</span>
              </div>
            </a>
          </button>

          <button
            id="account"
            type="button"
            class="btn btn-secondary button-inactive"
          >
            <a href="/about-us">
              <div class="selector-holder">
                <i class="material-icons">info</i>
                <span>About Us</span>
              </div>
            </a>
          </button>
        </div>
      </div>
  </div>
</template>

<script>
export default({
  components: {},
  data() {
    return {};
  },
  props: {},
});
</script>
<style>
.rxix-menu li.nav-item a.nav-link {
  color: #00979e;
}
strong {
  margin-left: 20px;
  font-size: 20px;
  color: black;
}
button.accordion-button.collapsed.text-color.border-color {
  background-color: currentColor;
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: currentColor;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
</style>
